<template>
  <v-container fill-height>
    <v-row class="loginContainer" justify="center" align="center">

     
      <v-col cols="12" sm="8" md="6" lg="4">
         <h1>Sadaii</h1>
        <v-card class="elevation-12">
          <v-app-bar text color="white">
            <v-toolbar-title class="titleApp"
              >Panel Administrativo</v-toolbar-title
            >
          </v-app-bar>
          <v-card-text>
            <v-form ref="form" v-model="form.valid" lazy-validation>
              <v-text-field
                v-model="email"
                prepend-icon="person"
                name="login"
                label="Correo electrónico"
                type="text"
                :rules="[form.required, form.email]"
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                @click:append="show2 = !show2"
                prepend-icon="lock"
                name="password"
                label="Contraseña"
                :rules="[form.required]"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-layout justify-center>
              <v-btn
                @click="singIn()"
                :loading="loading3"
                rounded
                class="my-2"
                 dark
                > Entrar</v-btn
              >
            </v-layout>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import router from "../../router/index";
export default {
  name: "login",
  data: () => ({
    show2: false,
    loading3: false,
    form: {
      valid: false,
      required: (v) => !!v || "Este campo es necesario",
      email: (v) => /.+@.+/.test(v) || "El correo electrónico debe ser válido",
    },
    email: null,
    password: null,
  }),
  beforeMount() {
    if (this.userAdmin) {
      this.$router.push("/home");
    }
  },
  computed: {
    ...mapGetters(["userAdmin"]),
  },
  methods: {
    ...mapActions(["signinUserAdminInFirebase"]),

    clear() {
      this.$refs.form.reset();
    },
    async singIn() {
      if (!this.form.valid) {
        this.$emit("show-snackbar", "No puede dejar ningun campo vacio");
        return;
      }
      if (this.email.toLowerCase() != "admin@gmail.com") {
        this.$emit("show-snackbar", "Error al entrar al admin");
        return;
      }
      this.loading3 = true;
      const user = {
        email: this.email,
        password: this.password,
      };

      await this.signinUserAdminInFirebase({
        user,
        alternate: false,
      })
        .then(async (user) => {})
        .catch((err) => {
          this.$emit("show-snackbar", "Correo o contraseña son invalido.");
        });
      this.loading3 = false;
    },
  },
};
</script>
<style scoped>
.loginContainer {
  margin-top: 15% !important;
}
</style>
